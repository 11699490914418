import { Button } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { exportToXLSX } from 'utils/exportToExcel';
import { saveToFile } from 'utils/saveToFile';
import { opportunitiesTableHead } from 'constants/opportunitiesTable';
import { forEach, keyBy, mapValues } from 'lodash';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useLoadProposals } from 'template/Proposals/hooks/loadProposals';
import { ProgressInfo } from 'components/ProgressInfo';
import { MultiButtonDialog } from 'components/MultiButtonDialog';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const fileName = 'Opportunities';
const fileExtension = '.xlsx';

export interface IExportToExcelProps {
  pageLoadParams: any;
  totalItems: number;
}

export const ExportToExcel: FC<IExportToExcelProps> = ({ pageLoadParams, totalItems }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [showProgressInfo, setShowProgressInfo] = useState(false);

  const { loadProposals, loadProposalsStatus } = useLoadProposals();
  const {
    status: loadingExportDataStatus,
    stages: loadingExportDataStages,
    progress: loadingExportDataProgress,
  } = loadProposalsStatus;

  const headCells = opportunitiesTableHead;
  const order = pageLoadParams.order || SortOrder.ASC;

  const { rowsPerPage, page, orderBy, filter } = pageLoadParams;

  const variables = useMemo(() => {
    return {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter,
    };
  }, [filter, order, orderBy, page, rowsPerPage]);

  const headings = useMemo(() => {
    return mapValues(
      keyBy(
        headCells.map(({ id, label }) => ({ id, label })),
        'id'
      ),
      'label'
    );
  }, [headCells]);

  const headingStyles = useMemo(() => {
    return headCells.map(({ excelHeadStyle }) => excelHeadStyle);
  }, [headCells]);

  const rowStyles = useMemo(() => {
    return headCells.map(({ excelRowStyle }) => excelRowStyle);
  }, [headCells]);

  const dataConvert = useCallback(
    (dataCells: Array<any>) => {
      return dataCells?.map((row, index) => {
        const columns: any[] = [];

        forEach(headCells, (headCell) => {
          columns.push({
            id: headCell.id,
            value: headCell.transformFunction
              ? headCell.transformFunction(
                  row[headCell.id],
                  row,
                  order,
                  orderBy === headCell.sortBy || orderBy === headCell.id
                )
              : row[headCell.id],
          });
        });
        return mapValues(keyBy(columns, 'id'), 'value');
      });
    },
    [headCells, order, orderBy]
  );

  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (!['stop', 'fin'].includes(loadingExportDataStatus)) {
      setShowProgressInfo(true);
    }
    if (['fin'].includes(loadingExportDataStatus)) {
      setTimeout(() => setShowProgressInfo(false), 2000);
    }
  }, [loadingExportDataStatus]);

  const exportData = useCallback(
    (dataCells: Array<any>) => {
      const data = dataConvert(dataCells);

      const xlsxData = exportToXLSX({
        sheets: [
          { sheetName: fileName, csvData: data, headings, headerStyles: headingStyles, rowStyles },
        ],
      });

      saveToFile(xlsxData, fileName, fileExtension);
    },
    [headings, dataConvert, headingStyles, rowStyles]
  );

  const exportPages = useCallback(
    async (allPages: boolean) => {
      let result;
      if (!allPages) {
        result = await loadProposals({ allPages, rowsPerPage, variables });
      } else {
        const maxRowsPerPage = 100;
        result = await loadProposals({ allPages, rowsPerPage: maxRowsPerPage, variables });
      }
      if (!result.error) {
        exportData(result.data);
      }
    },
    [exportData, loadProposals, variables, rowsPerPage]
  );

  useEffect(() => {
    if (exporting) {
      if (rowsPerPage > totalItems && page === 0) {
        exportPages(false);
        setExporting(false);
      } else {
        setConfirmationDialogOpen(true);
      }
    }
  }, [exporting, dataConvert, exportPages, page, rowsPerPage, totalItems]);

  const exportProgress = useMemo(() => {
    return loadingExportDataStages.map(({ key, name }) => ({
      name,
      progress: Math.floor(
        (100 * loadingExportDataProgress[key].current) / loadingExportDataProgress[key].steps
      ),
    }));
  }, [loadingExportDataProgress, loadingExportDataStages]);

  return (
    <>
      <ProgressInfo
        open={showProgressInfo}
        title={'Export to Excel is in progress'}
        message={'Collecting data. Please wait...'}
        onClose={() => setShowProgressInfo(false)}
        progress={exportProgress}
      />
      <MultiButtonDialog
        open={confirmationDialogOpen}
        title={'Export to Excel'}
        message={'Please choose to export selected page or all pages'}
        onClose={() => {
          setExporting(false);
          setConfirmationDialogOpen(false);
        }}
        confirmButtons={[
          {
            title: 'Export Page',
            onClick: () => {
              exportPages(false);
              setExporting(false);
              setConfirmationDialogOpen(false);
            },
          },
          {
            title: 'Export All',
            onClick: () => {
              exportPages(true);
              setExporting(false);
              setConfirmationDialogOpen(false);
            },
          },
        ]}
      ></MultiButtonDialog>
      <div style={{ padding: '0 20px' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={debounce(() => {
            setExporting(true);
          }, DEBOUNCE_TIMEOUT)}
        >
          Export to Excel
        </Button>
      </div>
    </>
  );
};
