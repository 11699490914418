import { gql } from '@apollo/client';

export const IProposalEvent = gql`
  fragment IProposalEvent on ProposalEvent {
    eventType
    payload
    createdAt
    author {
      persona
      user {
        id
        name
        email
      }
    }
    subject {
      user {
        name
      }
    }
    file {
      originalFilename
    }
  }
`;

export const IProposalFile = gql`
  fragment IProposalFile on ProposalFile {
    id
    proposalFileType
    proposalFileGroup
    originalFilename
    blobContainer
    blobName
    mimeType
    createdAt
    downloadUrl
    event {
      ...IProposalEvent
    }
  }
  ${IProposalEvent}
`;
