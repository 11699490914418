import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, Divider, Grid, TextField, TextareaAutosize, Typography } from '@mui/material';
import { AlertDialog, ConfirmationDialog, EasyAutoComplete } from 'components';
import {
  ProposalBondFileType,
  ProposalBondType,
  ProposalFileType,
} from 'graphql/proposals/types/graphql-types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { GroupByTwoGrid } from 'components/GroupByTwoGrid/GroupByTwoGrid';
import { FieldRow } from 'components/GroupByTwoGrid/components/FieldRow/FieldRow';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import s from './style.module.scss';
import { useComponentContext } from 'template/Proposal/ProposalContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { CurrencyInputField } from 'template/Proposal/components/ProposalForm/components/CurrencyInputField/CurrencyInputField';
import { SelectCurrency } from 'components/SelectCurrency/SelectCurrency';
import { useNewBondFileVersion } from 'template/Proposal/components/NewBondFile/newBondFileHook';
import { AttachFile } from 'components/AttachFile/AttachFile';
import { IProposalFile } from 'graphql/proposals/types/IProposalFile';
import { ProposalBondFileInfo } from '../ProposalBondFileInfo/ProposalBondFileInfo';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useGenericArrayToDropdown } from 'hooks/genericArrayToDropdownHook';
import { bondWorkflowStates } from 'template/Proposal/components/ProposalForm/constants/bondWorkflow';

const bondTypes = [
  { key: ProposalBondType.BID_BOND, name: 'Bid Bond' },
  { key: ProposalBondType.PERFORMANCE_BOND, name: 'Performance Bond' },
];

export interface IProposalBondData {
  id?: string;
  type?: ProposalBondType;
  amount?: number;
  tenderNumber?: string;
  tenderName?: string;
  contractNumber?: string;
  contractName?: string;
  validityThroughDate?: any | null;
  recipientReceiveDeadline?: any | null;
  description?: string;
  currency?: string;
  beneficiaryNameAndAddress?: string;
  beneficiaryPhoneNumber?: string;
  beneficiaryFaxNumber?: string;
  beneficiaryEmail?: string;
  issuingBankRequirements?: string;
  wordingOrFormatRequirements?: string;
  deliveryInstructions?: string;
  files?: IProposalFile[];
  stage?: string | null;
  isLocked?: boolean;

  createdAt?: any;
  updatedAt?: any;

  errors?: any;
  isValid?: boolean;
}

export interface IProposalBondProps {
  bond: IProposalBondData;

  setBond: any;

  showValidator: boolean;

  onValidateAndSubmit: () => Promise<
    { result?: any; isError: boolean; errors?: any; bondId: string } | undefined
  >;
  showResult: ({ result, isError, errors }: { result: any; isError: boolean; errors: any }) => void;
  onDeleteProcess: any;
  onLockProcess?: () => Promise<void>;
  onUnLockProcess?: () => Promise<void>;
  refetchFilesList?: () => Promise<void>;
}

export const ProposalBond: FC<IProposalBondProps> = ({
  bond,
  setBond,
  showValidator,

  onValidateAndSubmit,
  showResult,
  onDeleteProcess,
  onLockProcess,
  onUnLockProcess,

  refetchFilesList,
}) => {
  const { errors, stage } = bond;

  const history = useHistory();
  const { checkAndProceed, resetChanged, formChanged } = useFormChangedDialogContext();

  const {
    proposal: { id: proposalId },
  } = useComponentContext();

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayLockWarning, showLockWarning] = useState(false);

  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const selectedBondStage = useMemo(() => {
    return bondWorkflowStates.find(({ id }) => id === stage);
  }, [stage]);

  const selectedBondType = useMemo(() => {
    return bondTypes.find((type) => type.key === bond.type);
  }, [bond]);

  const { id, files: bondFiles, createdAt, updatedAt } = bond;

  const gotoBidAndPerformanceBondList = useCallback(() => {
    history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', proposalId));
  }, [history, proposalId]);

  const isNewItem = useMemo(() => !id, [id]);

  const validationSummary = useMemo(() => {
    return [];
  }, []);

  const handleDeleteBond = useCallback(
    async (confirm: boolean) => {
      showDeleteWarning(() => false);
      if (confirm) {
        if (await onDeleteProcess!()) {
          resetChanged && resetChanged();
          history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', proposalId));
        }
      }
    },
    [onDeleteProcess, history, resetChanged, proposalId]
  );

  const handleLockBond = useCallback(
    async (confirm: boolean) => {
      showLockWarning(() => false);
      if (confirm) {
        onLockProcess && onLockProcess();
      }
    },
    [onLockProcess]
  );

  // to set validation props:
  useEffect(() => {
    setBond((bond: any) => bond);
  }, [setBond]);

  const refetchBond = useCallback(async () => {
    resetChanged && resetChanged();
    // refetch && (await refetch());
  }, [resetChanged]);

  const uploadStart = () => {
    setUploadingLoading(true);
  };

  const uploadEnd = (uploadResult: any) => {
    setUploadingLoading(false);

    if (uploadResult.hasErrors === true) {
      setAlertMessage(uploadResult.errorMessage);
      setAlertOpen(true);
    }
  };

  const bondFileWordingUpload = useNewBondFileVersion({
    proposalBond: bond,
    fileType: ProposalBondFileType.WORDING_OR_FORMAT_REQUIREMENT,
    refetchDocument: refetchBond,
    onUploadStart: uploadStart,
    onUploadEnd: uploadEnd,
    multiple: true,
  });

  const bondFileUpload = useNewBondFileVersion({
    proposalBond: bond,
    fileType: ProposalBondFileType.BOND,
    refetchDocument: refetchBond,
    onUploadStart: uploadStart,
    onUploadEnd: uploadEnd,
    multiple: true,
  });

  const processSubmit = useCallback(async () => {
    const submitResult = await onValidateAndSubmit();
    if (submitResult) {
      const { errors, isError, result, bondId } = submitResult;
      if (!isError) {
        await bondFileWordingUpload.submit(bondId);
        await bondFileUpload.submit(bondId);
      }
      await showResult({ errors, isError, result });
    }
  }, [bondFileWordingUpload, bondFileUpload, onValidateAndSubmit, showResult]);

  const proposalBondStagePairs = useMemo(() => {
    return bondWorkflowStates.map((item) => ({
      id: item.id as string,
      name: item.name,
    }));
  }, []);

  const proposalBondStageProps = useGenericArrayToDropdown({
    selectedItem: selectedBondStage,
    options: proposalBondStagePairs,
    sort: (a, b) => a.name.localeCompare(b.name),
  });

  return (
    <div>
      <AlertDialog
        title="Error"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Bond deletion"
        message={'Selected Bond will be deleted! The action is irreversible!'}
        onClose={handleDeleteBond}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <ConfirmationDialog
        open={displayLockWarning}
        title="Please confirm Bond lock"
        message={'Selected Bond will be locked!'}
        onClose={handleLockBond}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <div
        className={s.linkStyle}
        onClick={debounce(
          checkAndProceed!(() => {
            gotoBidAndPerformanceBondList();
          }),
          DEBOUNCE_TIMEOUT
        )}
      >
        Go back to bonds list
      </div>
      <Box mt={4} />
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <GroupByTwoGrid>
          <FieldRow title="Bond Type" locked={bond.isLocked}>
            <EasyAutoComplete
              key={`type-${bond.type ?? 'bondtype'}`}
              items={bondTypes}
              selected={selectedBondType}
              label=""
              textFieldStyle="outlined"
              optionsLabel="name"
              selectedChanged={(value: any): void => {
                formChanged && formChanged();
                setBond({ ...bond, type: value.key });
              }}
              error={showValidator && errors?.type}
              getOptionSelected={(option: any, value: any) => {
                return option.key === value.key;
              }}
              disabled={bond.isLocked}
            />
          </FieldRow>
        </GroupByTwoGrid>

        <Grid item xs={12}>
          <Grid
            container
            columns={26}
            spacing={2}
            columnSpacing={{ xs: 2, md: 7 }}
            alignContent="center"
            alignItems="center"
          >
            <FieldRow
              title="Bond Stage"
              columns={26}
              md1={8}
              lg1={5}
              md2={18}
              lg2={14}
              locked={bond.isLocked}
            >
              <EasyAutoComplete
                key={`eac-${JSON.stringify(selectedBondStage)}`}
                label=""
                textFieldStyle="outlined"
                optionsLabel="name"
                selected={selectedBondStage}
                selectedChanged={(value: any) => {
                  formChanged && formChanged();
                  setBond({ ...bond, stage: value.id });
                }}
                {...proposalBondStageProps}
                getOptionSelected={(option: any, value: any) => {
                  return option.id === value.id;
                }}
                placeholder="Select Bond Stage..."
              />
            </FieldRow>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            columns={26}
            spacing={2}
            columnSpacing={{ xs: 2, md: 7 }}
            alignContent="center"
            alignItems="center"
          >
            <FieldRow
              title="Description"
              columns={26}
              md1={8}
              lg1={5}
              md2={18}
              lg2={14}
              locked={bond.isLocked}
            >
              <TextareaAutosize
                className="MuiInputBase-input"
                color="grey"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  minWidth: '100%',
                  minHeight: '1rem',
                  padding: 10,
                  borderRadius: 4,
                  border: '1px #ccc solid',
                  overflow: 'auto',
                  marginBottom: '1rem',
                }}
                aria-label="minimum height"
                minRows={4}
                value={bond.description || ''}
                onChange={(e: any) => {
                  formChanged && formChanged();
                  setBond({ ...bond, description: e.target.value });
                }}
                placeholder="Description…"
                disabled={bond.isLocked}
              ></TextareaAutosize>
            </FieldRow>
          </Grid>
        </Grid>

        {!!createdAt ? (
          <GroupByTwoGrid>
            <FieldRow title="Created Date" md1={4} md2={3}>
              <>
                {new Date(createdAt).toLocaleDateString('en-EN', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </>
            </FieldRow>
          </GroupByTwoGrid>
        ) : undefined}

        {!!updatedAt ? (
          <GroupByTwoGrid>
            <FieldRow title="Last Modified Date" md1={4} md2={3}>
              <>
                {new Date(updatedAt).toLocaleDateString('en-EN', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </>
            </FieldRow>
          </GroupByTwoGrid>
        ) : (
          <></>
        )}

        <>
          <Grid xs={12}>
            <Box m={3} />
            <Divider />
            <Box m={1} />
          </Grid>
        </>

        <GroupByTwoGrid>
          <FieldRow title="Tender Name" isRequired locked={bond.isLocked}>
            <TextareaAutosize
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border: showValidator && errors?.tenderName ? '1px red solid' : '1px #ccc solid',
                overflow: 'auto',
              }}
              aria-label="minimum height"
              minRows={1}
              value={bond.tenderName || ''}
              onChange={(e: any) => {
                formChanged && formChanged();
                setBond({ ...bond, tenderName: e.target.value });
              }}
              disabled={bond.isLocked}
              placeholder="Input tender name"
            ></TextareaAutosize>
          </FieldRow>
          <FieldRow title="Tender Number" isRequired locked={bond.isLocked}>
            <TextField
              variant="outlined"
              size="small"
              value={bond.tenderNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formChanged && formChanged();
                setBond({ ...bond, tenderNumber: e.target.value });
              }}
              fullWidth
              error={showValidator && errors?.tenderNumber}
              disabled={bond.isLocked}
              placeholder="Input tender number"
            />
          </FieldRow>
        </GroupByTwoGrid>
        <GroupByTwoGrid>
          <FieldRow
            title="Contract Name"
            disabled={bond.type === ProposalBondType.BID_BOND}
            locked={bond.isLocked}
          >
            <TextField
              variant="outlined"
              size="small"
              value={bond.type === ProposalBondType.BID_BOND ? '' : bond.contractName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formChanged && formChanged();
                setBond({ ...bond, contractName: e.target.value });
              }}
              fullWidth
              error={showValidator && errors?.contractName}
              disabled={bond.type === ProposalBondType.BID_BOND || bond.isLocked}
              placeholder="Input contract name"
            />
          </FieldRow>
          <FieldRow
            title="Contract Number"
            disabled={bond.type === ProposalBondType.BID_BOND}
            locked={bond.isLocked}
          >
            <TextField
              variant="outlined"
              size="small"
              value={bond.type === ProposalBondType.BID_BOND ? '' : bond.contractNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formChanged && formChanged();
                setBond({ ...bond, contractNumber: e.target.value });
              }}
              fullWidth
              error={showValidator && errors?.contractNumber}
              disabled={bond.type === ProposalBondType.BID_BOND || bond.isLocked}
              placeholder="Input contract number"
            />
          </FieldRow>
        </GroupByTwoGrid>

        <>
          <Grid xs={12}>
            <Box m={3} />
            <Divider />
            <Box m={1} />
          </Grid>
        </>

        <GroupByTwoGrid>
          <FieldRow title="Amount" isRequired locked={bond.isLocked}>
            <CurrencyInputField
              currency={bond.currency}
              size="small"
              value={bond.amount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formChanged && formChanged();
                setBond({ ...bond, amount: parseFloat(e.target.value) });
              }}
              fullWidth
              error={showValidator && errors?.amount}
              disabled={bond.isLocked}
              placeholder="Input amount"
            />
          </FieldRow>
          <FieldRow title="Currency" isRequired locked={bond.isLocked}>
            <SelectCurrency
              currency={bond.currency}
              onSelectChange={(c: string) => {
                formChanged && formChanged();
                setBond({ ...bond, currency: c });
              }}
              error={showValidator && errors?.currency}
              disabled={bond.isLocked}
            ></SelectCurrency>
            {/* <TextField
              variant="outlined"
              size="small"
              value={bond.currency}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formChanged && formChanged();
                setBond({ ...bond, currency: e.target.value });
              }}
              fullWidth
              error={showValidator && errors?.Currency}
            /> */}
          </FieldRow>
        </GroupByTwoGrid>

        <>
          <Grid xs={12}>
            <Box m={3} />
            <Divider />
            <Box m={1} />
          </Grid>
        </>

        <div style={{ paddingLeft: '1rem', margin: '1rem 0 0 0', color: 'gray' }}>
          <Typography variant="body2" className="label-title-nocase">
            Beneficiary
          </Typography>
        </div>

        <GroupByTwoGrid alignItems="flex-start">
          <FieldRow title="Name & Address" isRequired locked={bond.isLocked}>
            <TextareaAutosize
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border:
                  showValidator && errors?.beneficiaryNameAndAddress
                    ? '1px red solid'
                    : '1px #ccc solid',
                overflow: 'auto',
              }}
              aria-label="minimum height"
              minRows={4}
              value={bond.beneficiaryNameAndAddress || ''}
              onChange={(e: any) => {
                formChanged && formChanged();
                setBond({ ...bond, beneficiaryNameAndAddress: e.target.value });
              }}
              disabled={bond.isLocked}
              placeholder="Input name and address"
            ></TextareaAutosize>
          </FieldRow>
          <Grid item xs={12}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <FieldRow title="Phone Number" locked={bond.isLocked}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bond.beneficiaryPhoneNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formChanged && formChanged();
                    setBond({ ...bond, beneficiaryPhoneNumber: e.target.value });
                  }}
                  fullWidth
                  error={showValidator && errors?.beneficiaryPhoneNumber}
                  disabled={bond.isLocked}
                  placeholder="Input phone number"
                />
              </FieldRow>
              <FieldRow title="Fax Number" locked={bond.isLocked}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bond.beneficiaryFaxNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formChanged && formChanged();
                    setBond({ ...bond, beneficiaryFaxNumber: e.target.value });
                  }}
                  fullWidth
                  error={showValidator && errors?.beneficiaryFaxNumber}
                  disabled={bond.isLocked}
                  placeholder="Input fax number"
                />
              </FieldRow>
              <FieldRow title="Email" locked={bond.isLocked}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={bond.beneficiaryEmail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formChanged && formChanged();
                    setBond({ ...bond, beneficiaryEmail: e.target.value });
                  }}
                  fullWidth
                  error={showValidator && errors?.beneficiaryEmail}
                  disabled={bond.isLocked}
                  placeholder="Input email"
                />
              </FieldRow>
            </Grid>
          </Grid>
        </GroupByTwoGrid>

        <Grid xs={12}>
          <Box m={3} />
          <Divider />
          <Box m={1} />
        </Grid>

        <GroupByTwoGrid>
          <FieldRow title="Deadline for Recipient Receipt" isRequired locked={bond.isLocked}>
            <DatePicker
              value={bond.recipientReceiveDeadline || null}
              onChange={(date) => {
                formChanged && formChanged();
                setBond({ ...bond, recipientReceiveDeadline: date });
              }}
              componentsProps={{
                actionBar: { actions: ['clear'] as PickersActionBarAction[] },
              }}
              renderInput={(props) => (
                <TextField
                  variant="outlined"
                  // fullWidth
                  style={{
                    backgroundColor: 'white',
                  }}
                  size="small"
                  {...props}
                  error={showValidator && errors?.recipientReceiveDeadline}
                />
              )}
              disabled={bond.isLocked}
            ></DatePicker>
          </FieldRow>
          <FieldRow title="Validity Through Date" isRequired locked={bond.isLocked}>
            <DatePicker
              value={bond.validityThroughDate || null}
              onChange={(date) => {
                formChanged && formChanged();
                setBond({ ...bond, validityThroughDate: date });
              }}
              componentsProps={{
                actionBar: { actions: ['clear'] as PickersActionBarAction[] },
              }}
              renderInput={(props) => (
                <TextField
                  variant="outlined"
                  // fullWidth
                  style={{ backgroundColor: 'white' }}
                  size="small"
                  {...props}
                  error={showValidator && errors?.validityThroughDate}
                />
              )}
              disabled={bond.isLocked}
            ></DatePicker>
          </FieldRow>
        </GroupByTwoGrid>

        <Grid xs={12}>
          <Box m={3} />
          <Divider />
          <Box m={1} />
        </Grid>

        <GroupByTwoGrid>
          <FieldRow title="Delivery Instructions" locked={bond.isLocked}>
            <TextareaAutosize
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border: '1px #ccc solid',
                overflow: 'auto',
              }}
              aria-label="minimum height"
              minRows={4}
              value={bond.deliveryInstructions || ''}
              onChange={(e: any) => {
                formChanged && formChanged();
                setBond({ ...bond, deliveryInstructions: e.target.value });
              }}
              placeholder="Delivery instructions..."
              disabled={bond.isLocked}
            ></TextareaAutosize>
          </FieldRow>
          <FieldRow title="Issuing Bank Requirements" locked={bond.isLocked}>
            <TextareaAutosize
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border: '1px #ccc solid',
                overflow: 'auto',
              }}
              aria-label="minimum height"
              minRows={4}
              value={bond.issuingBankRequirements || ''}
              onChange={(e: any) => {
                formChanged && formChanged();
                setBond({ ...bond, issuingBankRequirements: e.target.value });
              }}
              placeholder="Issuing bank requirements..."
              disabled={bond.isLocked}
            ></TextareaAutosize>
          </FieldRow>
        </GroupByTwoGrid>

        <Grid xs={12}>
          <Box m={3} />
          <Divider />
          <Box m={1} />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            columns={26}
            spacing={2}
            columnSpacing={{ xs: 2, md: 7 }}
            alignContent="center"
            alignItems="center"
          >
            <FieldRow
              title="Wording or Format Requirements"
              columns={26}
              md1={8}
              lg1={5}
              md2={18}
              lg2={14}
              locked={bond.isLocked}
            >
              <TextareaAutosize
                className="MuiInputBase-input"
                color="grey"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  minWidth: '100%',
                  minHeight: '1rem',
                  padding: 10,
                  borderRadius: 4,
                  border: '1px #ccc solid',
                  overflow: 'auto',
                  marginBottom: '1rem',
                }}
                aria-label="minimum height"
                minRows={4}
                value={bond.wordingOrFormatRequirements || ''}
                onChange={(e: any) => {
                  formChanged && formChanged();
                  setBond({ ...bond, wordingOrFormatRequirements: e.target.value });
                }}
                placeholder="Wording or format requirements..."
                disabled={bond.isLocked}
              ></TextareaAutosize>
              <LoadingOverlay
                active={uploadingLoading}
                spinner
                text="Please wait, upload in progress..."
              >
                <AttachFile
                  onDrop={bondFileWordingUpload.onDrop}
                  removeFile={bondFileWordingUpload.removeFile}
                  isUploading={bondFileWordingUpload.isUploading}
                  percentage={bondFileWordingUpload.percentage}
                  files={bondFileWordingUpload.files}
                  currentDocumentFileVersion={null}
                  multiple
                  disabled={bond.isLocked}
                />
                <div style={{ paddingTop: '1rem' }}>
                  {bondFiles
                    ?.filter((file) => file.proposalFileType === ProposalFileType.BOND_REQUIREMENTS)
                    .map((file, index) => (
                      <div key={file.originalFilename}>
                        {!!index && (
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '46px' }}></div>
                            <div className={s.fileInfo}>
                              <Box m={0.5} />
                              <Divider />
                              <Box m={0} />
                            </div>
                          </div>
                        )}
                        <div style={{ padding: '0.5rem 0 0 0' }}>
                          <ProposalBondFileInfo
                            proposalBondFile={file}
                            proposalId={proposalId}
                            refetchProposal={refetchFilesList ? refetchFilesList : undefined}
                            disabled={bond.isLocked}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </LoadingOverlay>
            </FieldRow>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Box m={3} />
          <Divider />
          <Box m={1} />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            columns={26}
            spacing={2}
            columnSpacing={{ xs: 2, md: 7 }}
            alignContent="center"
            alignItems="center"
          >
            <FieldRow
              title={`${
                bond.type === ProposalBondType.BID_BOND ? 'Bid Bond' : 'Performance Bond'
              } Files`}
              columns={26}
              md1={8}
              lg1={5}
              md2={18}
              lg2={14}
              locked={bond.isLocked}
            >
              <LoadingOverlay
                active={uploadingLoading}
                spinner
                text="Please wait, upload in progress..."
              >
                <AttachFile
                  onDrop={bondFileUpload.onDrop}
                  removeFile={bondFileUpload.removeFile}
                  isUploading={bondFileUpload.isUploading}
                  percentage={bondFileUpload.percentage}
                  files={bondFileUpload.files}
                  currentDocumentFileVersion={null}
                  multiple
                  disabled={bond.isLocked}
                />
                <div style={{ paddingTop: '1rem' }}>
                  {bondFiles
                    ?.filter((file) => file.proposalFileType === ProposalFileType.BOND)
                    .map((file, index) => (
                      <div key={file.originalFilename}>
                        {!!index && (
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '46px' }}></div>
                            <div className={s.fileInfo}>
                              <Box m={0.5} />
                              <Divider />
                              <Box m={0} />
                            </div>
                          </div>
                        )}
                        <div style={{ padding: '0.5rem 0 0 0' }}>
                          <ProposalBondFileInfo
                            proposalBondFile={file}
                            proposalId={proposalId}
                            refetchProposal={refetchFilesList ? refetchFilesList : undefined}
                            disabled={bond.isLocked}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </LoadingOverlay>
            </FieldRow>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <Box m={3} />
        <Divider />
        <Box m={1} />
      </Grid>

      <Box m={5} />
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container justifyContent="center" spacing={3}>
            {!isNewItem && !bond.isLocked ? (
              <Grid item xs={12} sm={2} order={{ xs: 5, sm: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showDeleteWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  style={{ background: 'red' }}
                >
                  Delete
                </Button>
              </Grid>
            ) : undefined}

            <Grid item xs={12} sm={3} order={{ xs: 2, sm: 4 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(
                  checkAndProceed!(() => {
                    history.push(
                      paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', proposalId)
                    );
                  }),
                  DEBOUNCE_TIMEOUT
                )}
              >
                Cancel
              </Button>
            </Grid>

            {!bond.isLocked ? (
              <Grid item xs={12} sm={3} order={{ xs: 1, sm: 5 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(processSubmit, DEBOUNCE_TIMEOUT)}
                  disabled={showValidator && !!validationSummary.length}
                >
                  Submit
                </Button>
              </Grid>
            ) : undefined}

            {!isNewItem && !bond.isLocked ? (
              <Grid item xs={12} sm={2} order={{ xs: 6 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showLockWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  style={{ background: 'red' }}
                >
                  Lock
                </Button>
              </Grid>
            ) : undefined}

            {!isNewItem && bond.isLocked ? (
              <Grid item xs={12} sm={2} order={{ xs: 6 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    onUnLockProcess && onUnLockProcess();
                  }, DEBOUNCE_TIMEOUT)}
                >
                  Unlock
                </Button>
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
};
