import { IProposalBondData, ProposalBond } from './components/ProposalBond/ProposalBond';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ProposalBondAdd,
  ProposalBondModify,
  ProposalBondRemove,
  ProposalBondType,
} from 'graphql/proposals/types/graphql-types';
import {
  GET_PROPOSAL_BONDS,
  PROPOSAL_BOND_CHANGE_MUTATION,
  PROPOSAL_BOND_LOCK_MUTATION,
  PROPOSAL_BOND_UNLOCK_MUTATION,
} from 'graphql/proposals/proposals';
import { useLazyQuery, useMutation } from '@apollo/client';
import { proposalBonds } from 'graphql/proposals/types/proposalBonds';
import { tryUpdateProcedure } from 'utils/apollo';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useUI } from 'contexts/UiContext';
import paths from 'constants/paths';

import { validateBond } from './validator';
import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { omit } from 'lodash';
import { useCurrencies } from 'hooks/currencyHook';

interface IUrlParams {
  bondId: string;
  id: string;
}
export interface IProps {
  routes: Array<any>;
}

const getNewBond: () => ProposalBondAdd = () => ({
  type: ProposalBondType.BID_BOND,
  tenderNumber: '',
  tenderName: '',
  contractNumber: '',
  contractName: '',
  description: '',
  beneficiaryNameAndAddress: '',
  beneficiaryPhoneNumber: '',
  beneficiaryFaxNumber: '',
  beneficiaryEmail: '',
  amount: 0,
  currency: '',
  issuingBankRequirements: '',
  wordingOrFormatRequirements: '',
  deliveryInstructions: '',
  isLocked: false,
});

export const ProposalBidPerformanceBonds = () => {
  const { addSnackbar } = useUI();
  const [createOrUpdateMutation] = useMutation(PROPOSAL_BOND_CHANGE_MUTATION);
  const [bondLockMutation] = useMutation(PROPOSAL_BOND_LOCK_MUTATION);
  const [bondUnLockMutation] = useMutation(PROPOSAL_BOND_UNLOCK_MUTATION);

  const { resetChanged } = useFormChangedDialogContext();
  const { bondId, id } = useParams<IUrlParams>();
  const history = useHistory();
  const { openValidationResult } = useFormValidationReportContext();
  const { currencyPairs } = useCurrencies();

  const [loadedBond, setLoadedBond] = useState<IProposalBondData>();
  const [bond, setBond] = useState<IProposalBondData>();
  const [loadFilesOnly, setLoadFilesOnly] = useState(false);

  const [showValidator, setShowValidator] = useState(false);

  const defaultCurrency = useMemo(() => {
    let foundUSD = currencyPairs.find((pair) => pair.id === 'USD' || pair.id === '$');
    if (foundUSD) {
      return foundUSD;
    }
  }, [currencyPairs]);

  const setBondProxy = useCallback(
    (cbOrData: ((bond?: IProposalBondData) => IProposalBondData) | IProposalBondData) => {
      if (cbOrData instanceof Function) {
        setBond((bond) => {
          const newData = cbOrData(bond);
          return { ...newData, ...validateBond(newData) };
        });
      } else {
        setBond({ ...cbOrData, ...validateBond(cbOrData) });
      }
    },
    []
  );

  const [loadBonds, { called, data, loading, error, refetch }] = useLazyQuery<proposalBonds>(
    GET_PROPOSAL_BONDS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadFilesOnlyRef = useRef(false);
  useEffect(() => {
    loadFilesOnlyRef.current = loadFilesOnly;
  }, [loadFilesOnly]);

  useEffect(() => {
    if (data && !loading && !error) {
      const bonds = data.proposal_proposal.bonds;
      const foundBond = bonds.find((b) => b.id === bondId);
      if (foundBond) {
        if (!loadFilesOnlyRef.current) {
          setBond({ ...foundBond, ...validateBond(foundBond) });
          setLoadedBond(foundBond);
        } else {
          setBond((bond) => {
            const b = { ...bond, files: foundBond?.files };
            return { ...b, ...validateBond(b) };
          });
          setLoadFilesOnly(false);
        }
      }
    }
  }, [data, loading, error, bondId]);

  const loadBond = useCallback(async () => {
    const variables = {
      id,
    };

    if (called) {
      await refetch!(variables);
    } else {
      await loadBonds({ variables });
    }
  }, [refetch, called, loadBonds, id]);

  const refetchFilesList = useCallback(async () => {
    setLoadFilesOnly(true);
    loadBond();
  }, [setLoadFilesOnly, loadBond]);

  useEffect(() => {
    if (!bondId || bondId === 'new') {
      setBond(getNewBond());
    } else {
      loadBond();
    }
  }, [bondId, loadBond]);

  useEffect(() => {
    if (defaultCurrency) {
      setBond((bond) => {
        if (bond && !bond.currency) {
          return { ...bond, currency: defaultCurrency.id };
        }
        return bond;
      });
    }
  }, [defaultCurrency]);

  const isNewItem = useMemo(() => !bond?.id, [bond?.id]);

  // const compareFunctions = useMemo(
  //   () => ({
  //     // e.g. guidelineCodeId: (id: string) => loadedProposal?.guidelineCode?.id === id,
  //   }),
  //   [loadedBond]
  // );

  const onLock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondLockMutation({
          variables: {
            proposalBondId: bondId,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && result?.proposal_proposalBondLock?.isLocked) {
      addSnackbar!({
        text: 'Bond is Locked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to lock',
          severity: 'error',
        });
      }
    }
  }, [bondId, bondLockMutation, addSnackbar, loadBond, resetChanged]);

  const onUnLock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondUnLockMutation({
          variables: {
            proposalBondId: bondId,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && !result?.proposal_proposalBondLock?.isLocked) {
      addSnackbar!({
        text: 'Bond is Unlocked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to unlock',
          severity: 'error',
        });
      }
    }
  }, [bondId, addSnackbar, loadBond, resetChanged, bondUnLockMutation]);

  const onValidateAndSubmit = useCallback(async () => {
    if (!bond) {
      return;
    }

    const { isValid } = bond;
    if (!isValid) {
      setShowValidator(true);
      openValidationResult && openValidationResult();
      return;
    }

    const bondsToAdd: ProposalBondAdd[] = [];
    const bondsToModify: ProposalBondModify[] = [];

    const {
      id: bondId,
      amount,
      beneficiaryEmail,
      beneficiaryFaxNumber,
      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      contractName,
      contractNumber,
      currency,
      deliveryInstructions,
      description,
      issuingBankRequirements,
      recipientReceiveDeadline,
      tenderName,
      tenderNumber,
      type,
      validityThroughDate,
      wordingOrFormatRequirements,
      stage,
    } = bond;

    const prepareData = {
      proposalBondId: bondId,
      amount,
      beneficiaryEmail,
      beneficiaryFaxNumber,
      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      contractName: type === ProposalBondType.BID_BOND ? '' : contractName,
      contractNumber: type === ProposalBondType.BID_BOND ? '' : contractNumber,
      currency,
      deliveryInstructions,
      description,
      issuingBankRequirements,
      recipientReceiveDeadline,
      tenderName,
      tenderNumber,
      type,
      validityThroughDate,
      wordingOrFormatRequirements,
      stage,
    };

    if (isNewItem) {
      bondsToAdd.push(prepareData as ProposalBondAdd);
    } else {
      const requiredFields = ['proposalBondId'];
      const omitKeys: string[] = [];
      const loadedKeys = Object.keys(prepareData);
      // const compareFunctionsKeys = Object.keys(compareFunctions);
      if (loadedBond) {
        Object.keys(prepareData).forEach((key) => {
          if (requiredFields.includes(key)) {
            return;
          }

          if (
            Array.isArray(prepareData[key as keyof typeof prepareData]) &&
            !prepareData[key as keyof typeof prepareData].length
          ) {
            omitKeys.push(key);
            return;
          }

          if (
            loadedKeys.includes(key) &&
            prepareData[key as keyof typeof prepareData] ===
              loadedBond[key as keyof typeof loadedBond]
          ) {
            omitKeys.push(key);
            return;
          }

          if (typeof loadedBond[key as keyof typeof loadedBond] == 'boolean') {
            if (
              !loadedBond[key as keyof typeof loadedBond] ===
              !prepareData[key as keyof typeof prepareData]
            ) {
              omitKeys.push(key);
              return;
            }
          }

          // if (
          //   compareFunctionsKeys.includes(key) &&
          //   compareFunctions[key as keyof typeof compareFunctions](
          //     prepareData[key as keyof typeof prepareData]
          //   )
          // ) {
          //   omitKeys.push(key);
          // }
        });
      }

      const saveData = omitKeys.length ? omit(prepareData, omitKeys) : prepareData;

      if (Object.keys(saveData).length === 1) {
        return { isError: false, bondId: bond.id! };
      }
      bondsToModify.push(saveData as ProposalBondModify);
    }

    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        createOrUpdateMutation({
          variables: {
            proposalId: id,
            bondsToAdd,
            bondsToModify,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    let updatedOrCreatedBondId = bond?.id;
    if (!updatedOrCreatedBondId) {
      let payload;
      try {
        payload = JSON.parse(result.proposal_proposalBondChange.payload);
      } catch {}

      if (payload && payload.addedBondIds?.length) {
        updatedOrCreatedBondId = payload.addedBondIds[0];
      }
    }

    return { result, isError, errors, bondId: updatedOrCreatedBondId ?? '' };
  }, [id, bond, loadedBond, createOrUpdateMutation, isNewItem, openValidationResult]);

  const showResult = useCallback(
    ({ result, isError, errors }: { result: any; isError: boolean; errors: any }) => {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });

        resetChanged && resetChanged();
        if (!bond?.id) {
          let payload;
          try {
            payload = JSON.parse(result.proposal_proposalBondChange.payload);
          } catch {}

          if (payload && payload.addedBondIds?.length) {
            history.push(
              paths.client.PROPOSAL_BID_PERFORMANCE_BOND_VIEW.replace(':id', id).replace(
                ':bondId',
                payload.addedBondIds[0]
              )
            );
          } else {
            history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', id));
          }
        } else {
          loadBond();
        }
      }
    },
    [addSnackbar, history, bond, loadBond, resetChanged, id]
  );

  const onDeleteProcess = useCallback(async () => {
    if (!bond) {
      return;
    }
    const bondsToRemove: ProposalBondRemove[] = [{ proposalBondId: bond.id! }];

    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        createOrUpdateMutation({
          variables: {
            proposalId: id,
            bondsToRemove,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    if (isError || !result) {
      addSnackbar!({
        text: 'Error...' + errors?.join(' '),
        severity: 'error',
      });
    } else {
      addSnackbar!({
        text: 'Success',
        severity: 'success',
      });

      resetChanged && resetChanged();

      history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', id));
    }
    return !isError;
  }, [id, bond, createOrUpdateMutation, addSnackbar, history, resetChanged]);

  if (!bond) {
    return <></>;
  }
  return (
    <div>
      <div>
        <FormValidationReport
          errors={{
            ...bond.errors,
          }}
        ></FormValidationReport>
        <ProposalBond
          bond={bond}
          setBond={setBondProxy}
          showValidator={showValidator}
          onValidateAndSubmit={onValidateAndSubmit}
          showResult={showResult}
          onDeleteProcess={onDeleteProcess}
          refetchFilesList={refetchFilesList}
          onLockProcess={onLock}
          onUnLockProcess={onUnLock}
        ></ProposalBond>
      </div>
    </div>
  );
};
