import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ProposalBondType } from 'graphql/proposals/types/graphql-types';
import { TransformCurrencyUS } from 'utils/TableTransformFunctions';

export const bondsTableHead: IHeadCell[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Bond Type',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data) =>
      data === ProposalBondType.BID_BOND ? 'Bid Bond' : 'Performance Bond',
  },
  {
    id: 'tenderNumber',
    numeric: false,
    disablePadding: false,
    label: 'Tender Number',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'tenderName',
    numeric: false,
    disablePadding: false,
    label: 'Tender Name',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'contractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contract Number',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'contractName',
    numeric: false,
    disablePadding: false,
    label: 'Contract Name',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data: any) => (data ? data : '-- '),
    transformDataCell: TransformCurrencyUS,
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
    sortable: false,

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
];
