import { Table } from 'components/ui/Table/Table';
import { DEFAULT_ROWS_PER_PAGE, TABLE_PAGINATION } from 'constants/config';
import { useBidAndPerformanceBonds } from 'hooks/bidAndPerformanceBondsHook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { Box, Grid } from '@mui/material';
import { bondsTableHead } from 'constants/bondsTable';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import { IPageLoadParams } from 'hooks/tableDataSourceHook';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';

export interface IProposalBondsProps {
  proposalId: string;
}

const defaultFilterValues = {
  status: { id: 'All', name: 'All' },
};

export const ProposalBonds: FC<IProposalBondsProps> = ({ proposalId }) => {
  const history = useHistory();
  const { setItem, getItem } = useTableStorage({
    key: 'proposalBonds',
  });
  const { bonds, totalItems } = useBidAndPerformanceBonds({ proposalId });
  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filterValues: [],
    order: SortOrder.ASC,
    orderBy: undefined,
    ...getItem(),
  });

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const filterOptions: any[] = [];

  const items = useMemo(() => {
    const { page, rowsPerPage } = pageLoadParams;
    console.log('Slice', { page, rowsPerPage });
    return (bonds?.map((bond) => bond.bond) ?? []).slice(
      page * rowsPerPage,
      Math.min((page + 1) * rowsPerPage, bonds?.length ?? 0 - 1)
    );
  }, [bonds, pageLoadParams]);

  const onFilterChange = () => false;

  const onBondSelect = useCallback(
    (id: string) => {
      history.push(
        paths.client.PROPOSAL_BID_PERFORMANCE_BOND_VIEW.replace(':id', proposalId).replace(
          ':bondId',
          id
        )
      );
      return true;
    },
    [history, proposalId]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}></Box>
        <Box
          style={{
            borderRadius: '1px',
            boxShadow: '0px 2px 5px #00000030',
            border: '1px solid #00000030',
          }}
        >
          <Table
            totalItems={totalItems}
            dataCells={items}
            headCells={bondsTableHead}
            loadPage={loadPage}
            handleSelect={onBondSelect}
            filterOptions={filterOptions}
            filterValues={pageLoadParams.filterValues || defaultFilterValues}
            onFilterChange={onFilterChange}
            initRowsPerPage={pageLoadParams.rowsPerPage}
            paginationProps={{
              rowsPerPageOptions: TABLE_PAGINATION.includes(5)
                ? TABLE_PAGINATION
                : [5, ...TABLE_PAGINATION],
            }}
            initOrder={pageLoadParams.order || SortOrder.ASC}
            initOrderBy={pageLoadParams.orderBy}
            initPage={pageLoadParams.page}
          ></Table>
        </Box>
      </Grid>
    </Grid>
  );
};
