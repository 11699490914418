import { useQuery } from '@apollo/client';
import { GET_PROPOSAL_BONDS } from 'graphql/proposals/proposals';

import {
  proposalBonds,
  proposalBonds_proposal_proposal_bonds,
} from 'graphql/proposals/types/proposalBonds';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IUseBidAndPerformanceBondsProps {
  proposalId: string;
}

export interface IBondsList {
  bond: proposalBonds_proposal_proposal_bonds;
  changed: boolean;
  deleted: boolean;
  expanded: boolean;
}

export const useBidAndPerformanceBonds = ({ proposalId }: IUseBidAndPerformanceBondsProps) => {
  const [bonds, setBonds] = useState<IBondsList[]>();

  const totalItems = useMemo(() => bonds?.length ?? 0, [bonds]);

  const { data, loading, error } = useQuery<proposalBonds>(GET_PROPOSAL_BONDS, {
    variables: {
      id: proposalId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setBonds((prev) => {
        if (!prev) {
          return data.proposal_proposal.bonds.map((bond) => ({
            bond: { ...bond },
            changed: false,
            deleted: false,
            expanded: false,
          }));
        }
        return prev;
      });
    }
  }, [data, loading, error]);

  // useEffect(() => {
  //   if (!!bonds && !bonds.length) {
  //     setBonds((prev) => {
  //       if (prev && !prev?.length) {
  //         return [
  //           {
  //             bond: { type: ProposalBondType.BID_BOND },
  //             changed: false,
  //             deleted: false,
  //             expanded: true,
  //           },
  //         ];
  //       }
  //       return prev;
  //     });
  //   }
  // }, [bonds]);

  const setBond = useCallback((index: number, updates: any) => {
    setBonds((prev) => {
      if (prev && prev.length > index) {
        prev[index].bond = { ...prev[index].bond, ...updates };
        return [...prev];
      }
      return prev;
    });
  }, []);

  return {
    bonds,
    setBond,

    totalItems,
  };
};
