/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Application {
  CONTRACTS = "CONTRACTS",
  PROPOSALS = "PROPOSALS",
  REPORTS = "REPORTS",
}

export enum CountrySortableColumn {
  CODE = "CODE",
  ID = "ID",
  NAME = "NAME",
}

export enum DivisionSortableColumn {
  ID = "ID",
  NAME = "NAME",
}

export enum FieldSortableColumn {
  CODE = "CODE",
  COUNTRY_CODE = "COUNTRY_CODE",
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  NAME = "NAME",
  UPDATED_AT = "UPDATED_AT",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PartyContactType {
  REGULAR = "REGULAR",
}

export enum PartySortableColumn {
  ADDRESS = "ADDRESS",
  ADDRESS2 = "ADDRESS2",
  ALIAS = "ALIAS",
  CITY = "CITY",
  CLIENT_CODE = "CLIENT_CODE",
  COUNTRY_ID = "COUNTRY_ID",
  CREATED_AT = "CREATED_AT",
  DIVISION_OWNERSHIP_NAME = "DIVISION_OWNERSHIP_NAME",
  FOCAL_POINT_USER_NAME = "FOCAL_POINT_USER_NAME",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  NAME = "NAME",
  PARTY_DESIGNATION_ID = "PARTY_DESIGNATION_ID",
  PARTY_TYPE = "PARTY_TYPE",
  STATE = "STATE",
  ZIP = "ZIP",
}

export enum PartyTypeEnum {
  CLIENT = "CLIENT",
  NONCLIENT = "NONCLIENT",
}

export enum ProjectSortableColumn {
  AS_OF_DATE = "AS_OF_DATE",
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  KEY = "KEY",
  NAME = "NAME",
  PREDECESSOR = "PREDECESSOR",
  UPDATED_AT = "UPDATED_AT",
}

export enum ProposalActivity {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  STALE = "STALE",
}

export enum ProposalAggregateSortableColumn {
  ACTIVITY = "ACTIVITY",
  BID_PROPOSED_VALUE_SUM = "BID_PROPOSED_VALUE_SUM",
  COUNT = "COUNT",
  OWNING_DIVISION_NAME = "OWNING_DIVISION_NAME",
  PARTY_NAME = "PARTY_NAME",
  STAGE = "STAGE",
  SUCCESS_CHANCE = "SUCCESS_CHANCE",
}

export enum ProposalBondFileType {
  BOND = "BOND",
  WORDING_OR_FORMAT_REQUIREMENT = "WORDING_OR_FORMAT_REQUIREMENT",
}

export enum ProposalBondStage {
  BANK_APPLICATION_FILLED_OUT = "BANK_APPLICATION_FILLED_OUT",
  BANK_APPLICATION_REVIEWED = "BANK_APPLICATION_REVIEWED",
  BANK_APPLICATION_SUBMITTED = "BANK_APPLICATION_SUBMITTED",
  BANK_GUARANTEE_ISSUED_DIRECT_HARD_COPY = "BANK_GUARANTEE_ISSUED_DIRECT_HARD_COPY",
  BANK_GUARANTEE_ISSUED_SWIFT_TO_FOREIGN_BANK = "BANK_GUARANTEE_ISSUED_SWIFT_TO_FOREIGN_BANK",
  BANK_GUARANTEE_ISSUED_SWIFT_TO_US_OFFICE = "BANK_GUARANTEE_ISSUED_SWIFT_TO_US_OFFICE",
  GUARANTEE_REQUEST_FEEDBACK_PROVIDED = "GUARANTEE_REQUEST_FEEDBACK_PROVIDED",
  GUARANTEE_REQUEST_REQUESTED = "GUARANTEE_REQUEST_REQUESTED",
  GUARANTEE_REQUEST_REVIEWED = "GUARANTEE_REQUEST_REVIEWED",
  THIRD_PARTY_BANK_FEEDBACK_ADDRESSED = "THIRD_PARTY_BANK_FEEDBACK_ADDRESSED",
  THIRD_PARTY_BANK_FEEDBACK_PROVIDED = "THIRD_PARTY_BANK_FEEDBACK_PROVIDED",
  THIRD_PARTY_BANK_GUARANTEE_DELIVERED = "THIRD_PARTY_BANK_GUARANTEE_DELIVERED",
  THIRD_PARTY_BANK_GUARANTEE_ISSUED = "THIRD_PARTY_BANK_GUARANTEE_ISSUED",
}

export enum ProposalBondType {
  BID_BOND = "BID_BOND",
  PERFORMANCE_BOND = "PERFORMANCE_BOND",
}

export enum ProposalEntityType {
  OPPORTUNITY = "OPPORTUNITY",
  PROPOSAL = "PROPOSAL",
}

export enum ProposalFileGroup {
  BOND = "BOND",
  CLIENT_DOCUMENT = "CLIENT_DOCUMENT",
  COMMENT = "COMMENT",
  DEMAC_SUBMITTED_DOCUMENT = "DEMAC_SUBMITTED_DOCUMENT",
  DEMAC_SUPPORTING_DOCUMENT = "DEMAC_SUPPORTING_DOCUMENT",
}

export enum ProposalFileType {
  BOND = "BOND",
  BOND_REQUIREMENTS = "BOND_REQUIREMENTS",
  COMMENT = "COMMENT",
  COMMERCIAL_PROPOSAL = "COMMERCIAL_PROPOSAL",
  COMMUNICATION = "COMMUNICATION",
  JOINT_COMMERCIAL_AND_TECHNICAL_PROPOSAL = "JOINT_COMMERCIAL_AND_TECHNICAL_PROPOSAL",
  SUPPORT_DOCUMENT = "SUPPORT_DOCUMENT",
  TECHNICAL_PROPOSAL = "TECHNICAL_PROPOSAL",
}

export enum ProposalMonetaryBreakdownAggregateSortableColumn {
  COUNT = "COUNT",
  OWNING_DIVISION_NAME = "OWNING_DIVISION_NAME",
  PARTY_NAME = "PARTY_NAME",
  STAGE = "STAGE",
  SUCCESS_CHANCE = "SUCCESS_CHANCE",
  VALUE_SUM = "VALUE_SUM",
  YEAR = "YEAR",
}

export enum ProposalPriceType {
  HOURLY_BASED = "HOURLY_BASED",
  VALUE_BASED = "VALUE_BASED",
}

export enum ProposalReviewStatus {
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
  IDLE = "IDLE",
  PENDING = "PENDING",
}

export enum ProposalSortableColumn {
  ACTIVITY = "ACTIVITY",
  BID_PROPOSED_VALUE = "BID_PROPOSED_VALUE",
  CREATED_AT = "CREATED_AT",
  DUE_DATE = "DUE_DATE",
  ENTITY_TYPE = "ENTITY_TYPE",
  ID = "ID",
  NAME = "NAME",
  OWNING_DIVISION_NAME = "OWNING_DIVISION_NAME",
  PARTY_NAME = "PARTY_NAME",
  PENDING_REVIEWER_NAMES = "PENDING_REVIEWER_NAMES",
  PROPOSAL_TYPE = "PROPOSAL_TYPE",
  PROPOSAL_USER_LEAD_NAME = "PROPOSAL_USER_LEAD_NAME",
  RECEIVE_DATE = "RECEIVE_DATE",
  REFERENCE_PROPOSALS_COUNT = "REFERENCE_PROPOSALS_COUNT",
  REVIEW_STATUS = "REVIEW_STATUS",
  STAGE = "STAGE",
  SUBMISSION_METHOD_NAME = "SUBMISSION_METHOD_NAME",
  SUCCESS_CHANCE = "SUCCESS_CHANCE",
  UPDATED_AT = "UPDATED_AT",
  WORK_TYPE_NAME = "WORK_TYPE_NAME",
}

export enum ProposalStage {
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  LOST = "LOST",
  PENDING = "PENDING",
  POSTPONED = "POSTPONED",
  PROMOTED = "PROMOTED",
  WON = "WON",
}

export enum ProposalSuccessChance {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum ProposalType {
  EOI_MARKET_SURVEY = "EOI_MARKET_SURVEY",
  PROPOSAL_DIRECT_REQUEST = "PROPOSAL_DIRECT_REQUEST",
  PROPOSAL_TENDER = "PROPOSAL_TENDER",
}

export enum UserSortableColumns {
  CREATED_AT = "CREATED_AT",
  DISCIPLINE_CATEGORY = "DISCIPLINE_CATEGORY",
  DIVISION = "DIVISION",
  EMAIL = "EMAIL",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  IS_ASSETS_USER = "IS_ASSETS_USER",
  NAME = "NAME",
  OID = "OID",
  UPDATED_AT = "UPDATED_AT",
}

export interface CountryFilter {
  codeOrNameContains?: string | null;
  ids?: string[] | null;
}

export interface CountrySort {
  column: CountrySortableColumn;
  order: Order;
}

export interface DivisionFilter {
  application?: Application | null;
}

export interface DivisionSort {
  column: DivisionSortableColumn;
  order: Order;
}

export interface FieldFilter {
  ids?: string[] | null;
  codeOrNameContains?: string | null;
  nameContains?: string | null;
  codeContains?: string | null;
  countryCodes?: string[] | null;
}

export interface FieldSort {
  column: FieldSortableColumn;
  order: Order;
}

export interface PartyContactFilterInput {
  withDeleted?: boolean | null;
  partyIds?: string[] | null;
  firstNameOrLastNameContains?: string | null;
  contactType?: PartyContactType | null;
  isActive?: boolean | null;
  ids?: string[] | null;
}

export interface PartyFilterInput {
  ids?: string[] | null;
  nameContains?: string | null;
  aliasContains?: string | null;
  addressContains?: string | null;
  cityContains?: string | null;
  stateContains?: string | null;
  zipContains?: string | null;
  projectSetupClientCodeContains?: string | null;
  projectSetupClientCodeOrNameContains?: string | null;
  notesContains?: string | null;
  associatedClientsContains?: string | null;
  divisionOwnershipIds?: string[] | null;
  focalPointUserIds?: string[] | null;
  focalPointUserNameContains?: string | null;
  isActive?: boolean | null;
  partyType?: PartyTypeEnum | null;
  partyDesignationIds?: string[] | null;
  partyDesignationId?: string | null;
  countryIds?: string[] | null;
  countryId?: string | null;
  createdAt?: any | null;
  createdAtFrom?: any | null;
  createdAtTo?: any | null;
}

export interface PartySortInput {
  column: PartySortableColumn;
  order: Order;
}

export interface PartyStockExchangeAddInput {
  stockExchangeValue?: number | null;
  stockExchangeSymbol?: string | null;
  stockExchangeId?: number | null;
}

export interface PartyStockExchangeModifyInput {
  partyStockExchangeId: string;
  stockExchangeValue?: number | null;
  stockExchangeSymbol?: string | null;
  stockExchangeId?: number | null;
}

export interface ProjectFilter {
  keyOrNameContains?: string | null;
  keyContains?: string | null;
  nameContains?: string | null;
  isActive?: boolean | null;
  isMoscow?: boolean | null;
  partyIds?: string[] | null;
  contract_containerAssignable?: boolean | null;
  contract_containerId?: number | null;
}

export interface ProjectSort {
  column: ProjectSortableColumn;
  order: Order;
}

export interface ProposalAggregateGroup {
  party?: boolean | null;
  stage?: boolean | null;
  successChance?: boolean | null;
  activity?: boolean | null;
  owningDivision?: boolean | null;
}

export interface ProposalAggregateSort {
  column: ProposalAggregateSortableColumn;
  order: Order;
}

export interface ProposalBondAdd {
  type: ProposalBondType;
  tenderNumber: string;
  tenderName: string;
  contractNumber: string;
  contractName: string;
  description: string;
  beneficiaryNameAndAddress: string;
  beneficiaryPhoneNumber: string;
  beneficiaryFaxNumber: string;
  beneficiaryEmail: string;
  recipientReceiveDeadline?: any | null;
  validityThroughDate?: any | null;
  amount: number;
  currency: string;
  issuingBankRequirements: string;
  wordingOrFormatRequirements: string;
  deliveryInstructions: string;
  stage?: ProposalBondStage | null;
}

export interface ProposalBondModify {
  proposalBondId: string;
  type?: ProposalBondType | null;
  tenderNumber?: string | null;
  tenderName?: string | null;
  contractNumber?: string | null;
  contractName?: string | null;
  description?: string | null;
  beneficiaryNameAndAddress?: string | null;
  beneficiaryPhoneNumber?: string | null;
  beneficiaryFaxNumber?: string | null;
  beneficiaryEmail?: string | null;
  recipientReceiveDeadline?: any | null;
  validityThroughDate?: any | null;
  amount?: number | null;
  currency?: string | null;
  issuingBankRequirements?: string | null;
  wordingOrFormatRequirements?: string | null;
  deliveryInstructions?: string | null;
  stage?: ProposalBondStage | null;
}

export interface ProposalBondRemove {
  proposalBondId: string;
}

export interface ProposalCompetitorAddInput {
  competitorId?: string | null;
  competitorName?: string | null;
  hasWonBid?: boolean | null;
  bidValue?: number | null;
  notes?: string | null;
}

export interface ProposalCompetitorModifyInput {
  competitorId?: string | null;
  competitorName?: string | null;
  hasWonBid?: boolean | null;
  bidValue?: number | null;
  notes?: string | null;
}

export interface ProposalFilter {
  entityType?: ProposalEntityType | null;
  ids?: string[] | null;
  excludeIds?: string[] | null;
  nameContains?: string | null;
  partyIds?: string[] | null;
  stages?: ProposalStage[] | null;
  reviewStatuses?: ProposalReviewStatus[] | null;
  owningDivisionIds?: string[] | null;
  clientNameContains?: string | null;
  partyCreatedAtFrom?: any | null;
  partyCreatedAtTo?: any | null;
  proposalUserLeadNameContains?: string | null;
  proposalTypes?: ProposalType[] | null;
  successChances?: ProposalSuccessChance[] | null;
  workTypeIds?: string[] | null;
  submissionMethodIds?: string[] | null;
  bidProposedValueLow?: number | null;
  bidProposedValueHigh?: number | null;
  receiveDate?: any | null;
  dueDate?: any | null;
  createdAt?: any | null;
  createdAtFrom?: any | null;
  createdAtTo?: any | null;
  updatedAt?: any | null;
  updatedAtFrom?: any | null;
  updatedAtTo?: any | null;
  pendingReviewersNameContains?: string | null;
  activities?: ProposalActivity[] | null;
}

export interface ProposalMonetaryBreakdownAdd {
  value: number;
  year: number;
}

export interface ProposalMonetaryBreakdownAggregateFilter {
  yearFrom?: number | null;
  yearTo?: number | null;
  stages?: ProposalStage[] | null;
}

export interface ProposalMonetaryBreakdownAggregateGroup {
  year?: boolean | null;
  stage?: boolean | null;
  successChance?: boolean | null;
  party?: boolean | null;
  owningDivision?: boolean | null;
}

export interface ProposalMonetaryBreakdownAggregateSort {
  column: ProposalMonetaryBreakdownAggregateSortableColumn;
  order: Order;
}

export interface ProposalMonetaryBreakdownModify {
  monetaryTransactionBreakdownId: string;
  value?: number | null;
  year?: number | null;
}

export interface ProposalMonetaryBreakdownRemove {
  monetaryTransactionBreakdownId: string;
}

export interface ProposalOwnerAddInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface ProposalOwnerModifyInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface ProposalReviewerAddInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface ProposalReviewerModifyInput {
  userId: string;
  order?: number | null;
  isEnabled?: boolean | null;
}

export interface ProposalSort {
  column: ProposalSortableColumn;
  order: Order;
}

export interface StockExchangeFilter {
  withDeleted?: boolean | null;
  nameOrAcronymOrMicContains?: string | null;
  ids?: string[] | null;
}

export interface UploadedFileInput {
  blobContainerName: string;
  blobName: string;
  fileFilename: string;
  fileMimeType: string;
}

export interface UserFilter {
  oid?: string | null;
  nameContains?: string | null;
  emailContains?: string | null;
  nameOrEmailContains?: string | null;
  isActive?: boolean | null;
  isMoscow?: boolean | null;
  isAssetsUser?: boolean | null;
  divisionId?: string | null;
  projectId?: string | null;
}

export interface UserSort {
  column: UserSortableColumns;
  order: Order;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
