import validate from 'validate.js';
import { validators } from 'constants/validators';
import { IProposalBondData } from './components/ProposalBond/ProposalBond';

// https://validatejs.org/#validators-date
validate.extend(validate.validators.datetime, {
  parse: function (value: any) {
    // Try to parse the date using the Date constructor
    var parsedDate = Date.parse(value);
    if (!isNaN(parsedDate)) {
      return new Date(parsedDate);
    } else {
      return NaN; // Invalid date
    }
  },
  // Custom formatter function
  format: function (date: any) {
    // Convert the date to a readable string
    return date.toDateString();
  },
});

export const aliases: any = {
  // e.g. selectedUser: 'Current Assignee',
};

export const validatorOptions = {
  prettify: function prettify(string: string) {
    return aliases[string] || validate.prettify(string);
  },
};

export const bondValidators = {
  tenderName: validators.requiredAndNotEmpty,
  tenderNumber: validators.requiredAndNotEmpty,
  amount: validators.positiveCurrency,
  currency: validators.requiredAndNotEmpty,
  beneficiaryNameAndAddress: validators.requiredAndNotEmpty,
  recipientReceiveDeadline: validators.dateRequired,
  validityThroughDate: validators.dateRequired,
};

export const validateBond = (bond: IProposalBondData) => {
  let errors = validate(bond, bondValidators, validatorOptions);
  return { errors, isValid: errors ? false : true };
};
