import { gql } from '@apollo/client';
import { IProposalFile } from './fileManagerFragments';
import { IProposalDocument } from './proposalDocumentFragments';

export const GET_PROPOSAL_DOCUMENTS = gql`
  query proposalDocuments($id: ID!) {
    proposal_proposal(id: $id) {
      documents {
        ...IProposalDocument
      }
    }
  }
  ${IProposalDocument}
`;

export const GET_PROPOSAL_FILES = gql`
  query proposalFiles($id: ID!) {
    proposal_proposal(id: $id) {
      files {
        ...IProposalFile
      }
    }
  }
  ${IProposalFile}
`;

export const PROPOSAL_FILE_CREATE = gql`
  mutation proposalFileCreate(
    $proposalId: ID!
    $proposalFileType: ProposalFileType!
    $proposalFileGroup: ProposalFileGroup!
    $comment: String
    $uploadedFile: UploadedFileInput!
  ) {
    proposal_proposalFileCreate(
      proposalId: $proposalId
      proposalFileType: $proposalFileType
      proposalFileGroup: $proposalFileGroup
      comment: $comment
      uploadedFile: $uploadedFile
    ) {
      ...IProposalFile
    }
  }
  ${IProposalFile}
`;

export const PROPOSAL_BOND_FILE_CREATE = gql`
  mutation proposalBondFileCreate(
    $proposalBondId: ID!
    $fileType: ProposalBondFileType!
    $uploadedFile: UploadedFileInput!
  ) {
    proposal_proposalBondFileCreate(
      proposalBondId: $proposalBondId
      fileType: $fileType
      uploadedFile: $uploadedFile
    ) {
      ...IProposalFile
    }
  }
  ${IProposalFile}
`;

export const PROPOSAL_FILE_DELETE = gql`
  mutation proposalFileDelete($proposalId: ID!, $proposalFileId: ID!, $comment: String) {
    proposal_proposalFileDelete(
      proposalId: $proposalId
      proposalFileId: $proposalFileId
      comment: $comment
    )
  }
`;

export const PROPOSAL_DOCUMENT_SOFT_DELETE = gql`
  mutation proposalDocumentSoftDelete(
    $proposalId: ID!
    $proposalDocumentId: ID!
    $comment: String
  ) {
    proposal_proposalDocumentSoftDelete(
      proposalId: $proposalId
      proposalDocumentId: $proposalDocumentId
      comment: $comment
    )
  }
`;
