import { gql } from '@apollo/client';
import {
  ICurrency,
  IProposal,
  IProposalBond,
  IProposalEvent,
  IProposalListItem,
} from './proposalsFragments';

export const GET_CURRENCIES = gql`
  query currencies {
    currencies {
      ...ICurrency
    }
  }
  ${ICurrency}
`;

export const GET_PROPOSALS = gql`
  query proposals(
    $sort: [ProposalSort!] = []
    $filter: ProposalFilter
    $take: Int
    $skip: Int = 0
  ) {
    proposal_proposals(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IProposalListItem
    }
  }
  ${IProposalListItem}
`;

export const GET_PROPOSALS_EXPORT = gql`
  query proposalsExport(
    $sort: [ProposalSort!] = []
    $filter: ProposalFilter
    $take: Int
    $skip: Int = 0
  ) {
    proposal_proposals(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IProposal
    }
  }
  ${IProposal}
`;

export const GET_PROPOSAL_COUNT = gql`
  query proposalCount($filter: ProposalFilter) {
    proposal_proposalCount(filter: $filter)
  }
`;

export const GET_PROPOSAL = gql`
  query proposal($id: ID!) {
    proposal_proposal(id: $id) {
      ...IProposal
    }
  }
  ${IProposal}
`;

export const GET_PROPOSAL_BONDS = gql`
  query proposalBonds($id: ID!) {
    proposal_proposal(id: $id) {
      bonds {
        ...IProposalBond
      }
    }
  }
  ${IProposalBond}
`;

export const GET_PROPOSAL_TIMESTAMP = gql`
  query proposalTimestamp($id: ID!) {
    proposal_proposal(id: $id) {
      updatedAt
    }
  }
`;

export const PROPOSAL_CREATE_MUTATION = gql`
  mutation proposalCreate(
    $referenceOpportunityId: ID
    $partyId: ID!
    $entityType: ProposalEntityType!
    $name: String!
    $stage: ProposalStage
    $proposalType: ProposalType
    $proposalLeadUserId: ID
    $guidelineCodeId: ID
    $evaluationTypeId: ID
    $budgetBasisId: ID
    $successChance: ProposalSuccessChance
    $receiveDate: DateTime
    $dueDate: DateTime
    $workTypeDetails: String
    $scopeOfWorkDescription: String
    $businessDecisions: String
    $bidProposedValue: Float
    $bidFinalValue: Float
    $averageHourlyRate: Float
    $estimatedTotalHours: Float
    $stageReason: String
    $lostReason: String
    $expectedAwardDate: DateTime
    $expectedProjectStartDate: DateTime
    $expectedProjectEndDate: DateTime
    $taxRate: Float
    $feesValue: Float
    $expensesValue: Float
    $numberOfMatureFields: Int
    $numberOfGreenFields: Int
    $figuresRequired: Boolean
    $travelRequired: Boolean
    $otherTeamMembers: String
    $notes: String
    $billingBasisIdsToAdd: [ID!]
    $billingOriginIdsToAdd: [ID!]
    $countryIdsToAdd: [ID!]
    $deliverableIdsToAdd: [ID!]
    $fieldIdsToAdd: [ID!]
    $partyContactIdsToAdd: [ID!]
    $proposalReportTypeIdsToAdd: [ID!]
    $revenueAreaIdsToAdd: [ID!]
    $submissionMethodIdsToAdd: [ID!]
    $workTypeIdsToAdd: [ID!]
    $individualsInvolvedUserIdsToAdd: [ID!]
    $potentialTeamMemberUserIdsToAdd: [ID!]
    $owningDivisionId: ID
    $participatingDivisionIdsToAdd: [ID!]
    $bestTechnicalEstimateValue: Float
    $priceType: ProposalPriceType
  ) {
    proposal_proposalCreate(
      referenceOpportunityId: $referenceOpportunityId
      partyId: $partyId
      entityType: $entityType
      name: $name
      stage: $stage
      proposalType: $proposalType
      proposalLeadUserId: $proposalLeadUserId
      guidelineCodeId: $guidelineCodeId
      evaluationTypeId: $evaluationTypeId
      budgetBasisId: $budgetBasisId
      successChance: $successChance
      receiveDate: $receiveDate
      dueDate: $dueDate
      workTypeDetails: $workTypeDetails
      scopeOfWorkDescription: $scopeOfWorkDescription
      businessDecisions: $businessDecisions
      bidProposedValue: $bidProposedValue
      bidFinalValue: $bidFinalValue
      averageHourlyRate: $averageHourlyRate
      estimatedTotalHours: $estimatedTotalHours
      stageReason: $stageReason
      lostReason: $lostReason
      expectedAwardDate: $expectedAwardDate
      expectedProjectStartDate: $expectedProjectStartDate
      expectedProjectEndDate: $expectedProjectEndDate
      taxRate: $taxRate
      feesValue: $feesValue
      expensesValue: $expensesValue
      numberOfMatureFields: $numberOfMatureFields
      numberOfGreenFields: $numberOfGreenFields
      figuresRequired: $figuresRequired
      travelRequired: $travelRequired
      otherTeamMembers: $otherTeamMembers
      notes: $notes
      billingBasisIdsToAdd: $billingBasisIdsToAdd
      billingOriginIdsToAdd: $billingOriginIdsToAdd
      countryIdsToAdd: $countryIdsToAdd
      deliverableIdsToAdd: $deliverableIdsToAdd
      fieldIdsToAdd: $fieldIdsToAdd
      partyContactIdsToAdd: $partyContactIdsToAdd
      proposalReportTypeIdsToAdd: $proposalReportTypeIdsToAdd
      revenueAreaIdsToAdd: $revenueAreaIdsToAdd
      submissionMethodIdsToAdd: $submissionMethodIdsToAdd
      workTypeIdsToAdd: $workTypeIdsToAdd
      individualsInvolvedUserIdsToAdd: $individualsInvolvedUserIdsToAdd
      potentialTeamMemberUserIdsToAdd: $potentialTeamMemberUserIdsToAdd
      owningDivisionId: $owningDivisionId
      participatingDivisionIdsToAdd: $participatingDivisionIdsToAdd
      bestTechnicalEstimateValue: $bestTechnicalEstimateValue
      priceType: $priceType
    ) {
      id
    }
  }
`;

export const PROPOSAL_UPDATE_MUTATION = gql`
  mutation proposalUpdate(
    $id: ID!
    $partyId: ID
    $referenceOpportunityId: ID
    $entityType: ProposalEntityType
    $name: String
    $stage: ProposalStage
    $proposalType: ProposalType
    $guidelineCodeId: ID
    $evaluationTypeId: ID
    $budgetBasisId: ID
    $successChance: ProposalSuccessChance
    $receiveDate: DateTime
    $dueDate: DateTime
    $workTypeDetails: String
    $scopeOfWorkDescription: String
    $businessDecisions: String
    $bidProposedValue: Float
    $bidFinalValue: Float
    $averageHourlyRate: Float
    $estimatedTotalHours: Float
    $expectedAwardDate: DateTime
    $expectedProjectStartDate: DateTime
    $expectedProjectEndDate: DateTime
    $taxRate: Float
    $feesValue: Float
    $expensesValue: Float
    $numberOfMatureFields: Int
    $numberOfGreenFields: Int
    $figuresRequired: Boolean
    $travelRequired: Boolean
    $otherTeamMembers: String
    $notes: String
    $stageReason: String
    $lostReason: String
    $billingBasisIdsToAdd: [ID!]
    $billingBasisIdsToRemove: [ID!]
    $billingOriginIdsToAdd: [ID!]
    $billingOriginIdsToRemove: [ID!]
    $countryIdsToAdd: [ID!]
    $countryIdsToRemove: [ID!]
    $deliverableIdsToAdd: [ID!]
    $deliverableIdsToRemove: [ID!]
    $fieldIdsToAdd: [ID!]
    $fieldIdsToRemove: [ID!]
    $partyContactIdsToAdd: [ID!]
    $partyContactIdsToRemove: [ID!]
    $proposalReportTypeIdsToAdd: [ID!]
    $proposalReportTypeIdsToRemove: [ID!]
    $revenueAreaIdsToAdd: [ID!]
    $revenueAreaIdsToRemove: [ID!]
    $submissionMethodIdsToAdd: [ID!]
    $submissionMethodIdsToRemove: [ID!]
    $workTypeIdsToAdd: [ID!]
    $workTypeIdsToRemove: [ID!]
    $proposalLeadUserId: ID
    $individualsInvolvedUserIdsToAdd: [ID!]
    $individualsInvolvedUserIdsToRemove: [ID!]
    $potentialTeamMemberUserIdsToAdd: [ID!]
    $potentialTeamMemberUserIdsToRemove: [ID!]
    $owningDivisionId: ID
    $participatingDivisionIdsToAdd: [ID!]
    $participatingDivisionIdsToRemove: [ID!]
    $bidProposedReason: String
    $bestTechnicalEstimateValue: Float
    $priceType: ProposalPriceType
  ) {
    proposal_proposalUpdate(
      id: $id
      partyId: $partyId
      referenceOpportunityId: $referenceOpportunityId
      entityType: $entityType
      name: $name
      stage: $stage
      proposalType: $proposalType
      guidelineCodeId: $guidelineCodeId
      evaluationTypeId: $evaluationTypeId
      budgetBasisId: $budgetBasisId
      successChance: $successChance
      receiveDate: $receiveDate
      dueDate: $dueDate
      workTypeDetails: $workTypeDetails
      scopeOfWorkDescription: $scopeOfWorkDescription
      businessDecisions: $businessDecisions
      bidProposedValue: $bidProposedValue
      bidFinalValue: $bidFinalValue
      averageHourlyRate: $averageHourlyRate
      estimatedTotalHours: $estimatedTotalHours
      expectedAwardDate: $expectedAwardDate
      expectedProjectStartDate: $expectedProjectStartDate
      expectedProjectEndDate: $expectedProjectEndDate
      taxRate: $taxRate
      feesValue: $feesValue
      expensesValue: $expensesValue
      numberOfMatureFields: $numberOfMatureFields
      numberOfGreenFields: $numberOfGreenFields
      figuresRequired: $figuresRequired
      travelRequired: $travelRequired
      otherTeamMembers: $otherTeamMembers
      notes: $notes

      # removed (from the Create)
      #   entityType: $entityType

      # added (to the Create)
      stageReason: $stageReason
      lostReason: $lostReason

      billingBasisIdsToAdd: $billingBasisIdsToAdd
      billingBasisIdsToRemove: $billingBasisIdsToRemove
      billingOriginIdsToAdd: $billingOriginIdsToAdd
      billingOriginIdsToRemove: $billingOriginIdsToRemove
      countryIdsToAdd: $countryIdsToAdd
      countryIdsToRemove: $countryIdsToRemove
      deliverableIdsToAdd: $deliverableIdsToAdd
      deliverableIdsToRemove: $deliverableIdsToRemove
      fieldIdsToAdd: $fieldIdsToAdd
      fieldIdsToRemove: $fieldIdsToRemove
      partyContactIdsToAdd: $partyContactIdsToAdd
      partyContactIdsToRemove: $partyContactIdsToRemove
      proposalReportTypeIdsToAdd: $proposalReportTypeIdsToAdd
      proposalReportTypeIdsToRemove: $proposalReportTypeIdsToRemove
      revenueAreaIdsToAdd: $revenueAreaIdsToAdd
      revenueAreaIdsToRemove: $revenueAreaIdsToRemove
      submissionMethodIdsToAdd: $submissionMethodIdsToAdd
      submissionMethodIdsToRemove: $submissionMethodIdsToRemove
      workTypeIdsToAdd: $workTypeIdsToAdd
      workTypeIdsToRemove: $workTypeIdsToRemove

      proposalLeadUserId: $proposalLeadUserId
      individualsInvolvedUserIdsToAdd: $individualsInvolvedUserIdsToAdd
      individualsInvolvedUserIdsToRemove: $individualsInvolvedUserIdsToRemove
      potentialTeamMemberUserIdsToAdd: $potentialTeamMemberUserIdsToAdd
      potentialTeamMemberUserIdsToRemove: $potentialTeamMemberUserIdsToRemove

      owningDivisionId: $owningDivisionId
      participatingDivisionIdsToAdd: $participatingDivisionIdsToAdd
      participatingDivisionIdsToRemove: $participatingDivisionIdsToRemove

      bidProposedReason: $bidProposedReason
      bestTechnicalEstimateValue: $bestTechnicalEstimateValue
      priceType: $priceType
    ) {
      id
    }
  }
`;

export const PROPOSAL_COMPETITORS_CHANGE = gql`
  mutation proposal_competitorsChange(
    $proposalId: ID!
    $competitorsToAdd: [ProposalCompetitorAddInput!]
    $competitorsToModify: [ProposalCompetitorModifyInput!]
    $competitorIdsToRemove: [ID!]
  ) {
    proposal_competitorsChange(
      proposalId: $proposalId
      competitorsToAdd: $competitorsToAdd
      competitorsToModify: $competitorsToModify
      competitorIdsToRemove: $competitorIdsToRemove
    ) {
      eventType
    }
  }
`;

export const PROPOSAL_OWNERS_CHANGE = gql`
  mutation proposalOwnersChange(
    $proposalId: ID!
    $ownersToAdd: [ProposalOwnerAddInput!]
    $ownersToModify: [ProposalOwnerModifyInput!]
  ) {
    proposal_proposalOwnersChange(
      proposalId: $proposalId
      ownersToAdd: $ownersToAdd
      ownersToModify: $ownersToModify
    )
  }
`;

export const PROPOSAL_CANCEL_MUTATION = gql`
  mutation proposalCancel($proposalId: ID!) {
    proposal_proposalCancel(proposalId: $proposalId) {
      eventType
    }
  }
`;

export const PROPOSAL_DELETE_MUTATION = gql`
  mutation proposalDelete($proposalId: ID!) {
    proposal_proposalDelete(proposalId: $proposalId)
  }
`;

export const PROPOSAL_COMMENT_CREATE = gql`
  mutation proposalComment($proposalId: ID!, $comment: String!, $uploadedFile: UploadedFileInput) {
    proposal_proposalComment(
      proposalId: $proposalId
      comment: $comment
      uploadedFile: $uploadedFile
    ) {
      ...IProposalEvent
    }
  }
  ${IProposalEvent}
`;

export const PROPOSAL_REVIEWERS_CHANGE_MUTATION = gql`
  mutation proposalReviewersChange(
    $proposalId: ID!
    $reviewersToAdd: [ProposalReviewerAddInput!]
    $reviewersToModify: [ProposalReviewerModifyInput!]
  ) {
    proposal_proposalReviewersChange(
      proposalId: $proposalId
      reviewersToAdd: $reviewersToAdd
      reviewersToModify: $reviewersToModify
    ) {
      eventType
    }
  }
`;

export const PROPOSAL_BOND_CHANGE_MUTATION = gql`
  mutation proposalBondChange(
    $proposalId: ID!
    $bondsToAdd: [ProposalBondAdd!]
    $bondsToModify: [ProposalBondModify!]
    $bondsToRemove: [ProposalBondRemove!]
  ) {
    proposal_proposalBondChange(
      proposalId: $proposalId
      bondsToAdd: $bondsToAdd
      bondsToModify: $bondsToModify
      bondsToRemove: $bondsToRemove
    ) {
      eventType
      payload
    }
  }
`;

export const PROPOSAL_BOND_LOCK_MUTATION = gql`
  mutation proposalBondLock($proposalBondId: ID!) {
    proposal_proposalBondLock(proposalBondId: $proposalBondId) {
      id
      isLocked
    }
  }
`;

export const PROPOSAL_BOND_UNLOCK_MUTATION = gql`
  mutation proposalBondUnlock($proposalBondId: ID!) {
    proposal_proposalBondUnlock(proposalBondId: $proposalBondId) {
      id
      isLocked
    }
  }
`;

export const PROPOSAL_REQUEST_REVIEW = gql`
  mutation proposalRequestReview($proposalId: ID!, $userId: ID!, $proposalDocumentIds: [ID!]) {
    proposal_proposalRequestReview(
      proposalId: $proposalId
      userId: $userId
      proposalDocumentIds: $proposalDocumentIds
    ) {
      user {
        id
      }
    }
  }
`;

export const PROPOSAL_REVIEW_RECALL = gql`
  mutation proposalReviewRecall($proposalId: ID!, $userId: ID!) {
    proposal_proposalReviewRecall(proposalId: $proposalId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

export const PROPOSAL_SEND_REVIEW_REQUEST_REMINDER = gql`
  mutation proposalSendReviewRequestReminder($proposalId: ID!, $userId: ID!) {
    proposal_proposalSendReviewRequestReminder(proposalId: $proposalId, userId: $userId) {
      user {
        id
      }
    }
  }
`;

export const PROPOSAL_REVIEW_APPROVE = gql`
  mutation proposalReviewApprove($proposalId: ID!, $comment: String, $proposalDocumentIds: [ID!]) {
    proposal_proposalReviewApprove(
      proposalId: $proposalId
      comment: $comment
      proposalDocumentIds: $proposalDocumentIds
    ) {
      user {
        id
      }
    }
  }
`;

export const PROPOSAL_REVIEW_DISAPPROVE = gql`
  mutation proposalReviewDisapprove(
    $proposalId: ID!
    $comment: String
    $proposalDocumentIds: [ID!]
  ) {
    proposal_proposalReviewDisapprove(
      proposalId: $proposalId
      comment: $comment
      proposalDocumentIds: $proposalDocumentIds
    ) {
      user {
        id
      }
    }
  }
`;

export const PROPOSAL_MONETARY_BREAKDOWN_CHANGE = gql`
  mutation proposalMonetaryBreakdownChange(
    $proposalId: ID!
    $monetaryBreakdownsToAdd: [ProposalMonetaryBreakdownAdd!]
    $monetaryBreakdownsToModify: [ProposalMonetaryBreakdownModify!]
    $monetaryBreakdownsToRemove: [ProposalMonetaryBreakdownRemove!]
  ) {
    proposal_monetaryBreakdownChange(
      proposalId: $proposalId
      monetaryBreakdownsToAdd: $monetaryBreakdownsToAdd
      monetaryBreakdownsToModify: $monetaryBreakdownsToModify
      monetaryBreakdownsToRemove: $monetaryBreakdownsToRemove
    ) {
      eventType
    }
  }
`;
