const BASE = '/';
const LOGIN = '/login';
const NEW_PROPOSAL = '/new-proposal';
const PROPOSALS = '/';
const PROPOSAL_PAGES = '/proposal/:id/:tab';
const PROPOSAL_DETAILS = '/proposal/:id/details';
const PROPOSAL_FM = '/proposal/:id/file-management';
const PROPOSAL_EVENTS = '/proposal/:id/events-log';

const PROPOSAL_BID_PERFORMANCE_BOND_NEW = '/proposal/:id/bid-performance-bonds/new';
const PROPOSAL_BID_PERFORMANCE_BOND_VIEW = '/proposal/:id/bid-performance-bonds/:bondId';
const PROPOSAL_BID_PERFORMANCE_BONDS = '/proposal/:id/bid-performance-bonds';

const OPPORTUNITIES = '/opportunities';
const NEW_OPPORTUNITY = '/new-opportunity';
const OPPORTUNITY_PAGES = '/opportunity/:id/:tab';
const OPPORTUNITY_DETAILS = '/opportunity/:id/details';
const OPPORTUNITY_FM = '/opportunity/:id/file-management';
const OPPORTUNITY_EVENTS = '/opportunity/:id/events-log';
const OPPORTUNITY_NEW_PROPOSAL = '/opportunity/:id/new-proposal';
const OPPORTUNITY_CONVERT_TO_PROPOSAL = '/opportunity/:id/convert-to-proposal';

const CLIENT_MANAGEMENT = '/client-management';
const NEW_CLIENT = '/new-client';
const CLIENT_PAGES = '/client/:id/:tab';
const CLIENT_DETAILS = '/client/:id/details';
const CLIENT_EVENTS = '/client/:id/events-log';

const REPORTS = '/reports';
const REPORT_YEAR_SUMMARY = '/reports/year-summary/:year';
const REPORT_YEAR_BY_SUCCESS_CHANCE_SUMMARY = '/reports/year-summary-by-success-chance/:year';

const clientPathConst = {
  BASE,
  LOGIN,
  NEW_PROPOSAL,
  PROPOSALS,
  NEW_OPPORTUNITY,
  OPPORTUNITIES,
  CLIENT_MANAGEMENT,
  PROPOSAL_DETAILS,
  PROPOSAL_FM,
  PROPOSAL_EVENTS,
  PROPOSAL_BID_PERFORMANCE_BONDS,
  PROPOSAL_BID_PERFORMANCE_BOND_NEW,
  PROPOSAL_BID_PERFORMANCE_BOND_VIEW,

  PROPOSAL_PAGES,
  OPPORTUNITY_DETAILS,
  OPPORTUNITY_FM,
  OPPORTUNITY_EVENTS,
  OPPORTUNITY_PAGES,
  NEW_CLIENT,
  CLIENT_PAGES,
  CLIENT_DETAILS,
  CLIENT_EVENTS,
  OPPORTUNITY_NEW_PROPOSAL,
  OPPORTUNITY_CONVERT_TO_PROPOSAL,
  REPORTS,
  REPORT_YEAR_SUMMARY,
  REPORT_YEAR_BY_SUCCESS_CHANCE_SUMMARY,
};

export default clientPathConst;
