/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';

import { id } from 'utils/id';

import { azureStorageBondUpload } from 'utils/azureStorageBondUpload';
import { PROPOSAL_BOND_FILE_CREATE } from 'graphql/proposals/fileManager';

export const useNewBondFileVersion = ({
  proposalBond,
  fileType,
  refetchBondFile,
  onUploadStart,
  onUploadEnd,
  multiple,
}: any): any => {
  const [newBondFile, setNewBondFile] = useState(false);
  const [requireReapprovalFromAllStakeHolders, setRequireReapprovalFromAllStakeHolders] =
    useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [proposalBondVersionCreate, { loading: isUploading }] =
    useMutation(PROPOSAL_BOND_FILE_CREATE);

  const apolloClient = useApolloClient();

  const handleNewBondFile = useCallback(
    (value: any): void => {
      setNewBondFile(value);
    },
    [setNewBondFile]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!multiple) {
        setFiles(acceptedFiles);
      } else {
        setFiles((oldFiles) => {
          const files = [
            ...oldFiles,
            ...acceptedFiles.filter(
              (file: { path: string }) => !oldFiles.find((oldFile) => oldFile.path === file.path)
            ),
          ];
          return files;
        });
      }
    },
    [multiple]
  );

  const removeFile = useCallback(
    (filename: string) => () => {
      setFiles((files) => {
        return files.filter((file) => file.path !== filename);
      });
    },
    []
  );

  const removeFiles = useCallback(() => {
    setFiles([]);
  }, []);

  const handleOpenConfirmDialog = (): void => {
    setOpenConfirmDialog(true);
  };

  const submit = useCallback(
    async (bondId?: string): Promise<void> => {
      if (files) {
        onUploadStart();

        let hasErrors = false;

        try {
          for (const file of files) {
            const uploadResult = await azureStorageBondUpload(
              apolloClient,
              proposalBond?.id ?? bondId,
              file.name,
              file as any
            );

            if (uploadResult.hasErrors) {
              onUploadEnd({ hasErrors: true, errorMessage: uploadResult.errorMessage });
              hasErrors = true;
              // displayError(uploadResult.errorMessage);
              break;
            }

            const uploadedFile = {
              blobContainerName: uploadResult.containerName,
              blobName: uploadResult.blobName,
              fileFilename: uploadResult.fileFilename,
              fileMimeType: uploadResult.fileMimeType,
            };

            await proposalBondVersionCreate({
              variables: {
                proposalBondId: proposalBond?.id ?? bondId,
                uploadedFile,
                fileType,
              },
            });
          }
        } catch (e: any) {
          onUploadEnd({ hasErrors: true, errorMessage: e?.message });
          hasErrors = true;
        }

        if (!hasErrors) {
          onUploadEnd({ hasErrors: false, errorMessage: '' });

          removeFiles();
          setRequireReapprovalFromAllStakeHolders(false);
          refetchBondFile && refetchBondFile({ variables: { id: id() } });
        }
      }
    },
    [
      apolloClient,
      files,
      onUploadStart,
      onUploadEnd,
      proposalBond,
      proposalBondVersionCreate,
      fileType,
      refetchBondFile,
      removeFiles,
    ]
  );

  const handleCloseConfirmDialog = useCallback(
    async (result: any, bondId?: string): Promise<void> => {
      setOpenConfirmDialog(false);
      if (result === false) return;
      return await submit(bondId);
    },
    [setOpenConfirmDialog, submit]
  );

  const handleRequireReapprovalFromAllStakeHolders = useCallback((): void => {
    setRequireReapprovalFromAllStakeHolders((prev) => !prev);
  }, []);

  return {
    isUploading,
    files,
    onDrop,
    removeFile,
    removeFiles,
    openConfirmDialog,
    setOpenConfirmDialog,
    requireReapprovalFromAllStakeHolders,
    setRequireReapprovalFromAllStakeHolders,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleRequireReapprovalFromAllStakeHolders,
    newBondFile,
    handleNewBondFile,
    submit,
  };
};
